document.addEventListener("turbo:load", () => {
  document.querySelectorAll(".js-toggle-password").forEach((container) => {
    const password = container.querySelector('input');
    const eyeButton = container.querySelector('.fa-eye');

    eyeButton.addEventListener("click", function () {
      const type = password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
      this.classList.toggle('fa-eye');
      this.classList.toggle('fa-eye-slash');
    });
  });
});
