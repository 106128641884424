import { Controller } from "@hotwired/stimulus"

import IMask from 'imask';

// Connects to data-controller="input-mask"
export default class extends Controller {
  static targets = ["format"]

  formatTargetConnected(target) {
    IMask(target, {
      mask: target.dataset.inputMaskFormat
    });
  }
}
