import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ["back", "next", "preview", "slides", "slidesContainer", "mediaModal"];

  connect() {
    super.connect();

    this._nextSlide(1, true);
  }

  backTargetConnected(element) {
    element.addEventListener("click", () => this._prevSlide());
  }

  nextTargetConnected(element) {
    element.addEventListener("click", () => this._nextSlide());
  }

  slidesTargetConnected(slide) {
    if (slide.dataset.connected === "true") return;

    slide.addEventListener('click', (e) => {
      const index = Array.prototype.indexOf.call(this.slidesContainerTarget.children, slide);
      this._nextSlide(index + 1, true);
    });

    slide.dataset.connected = "true";
  }

  mediaModalTargetConnected(modal) {
    modal.addEventListener('hide.bs.modal', () => {
      const iframe = modal.querySelector('iframe')
      if (iframe !== null) {
        iframe.setAttribute("src", iframe.getAttribute("src"));
      }
    })
  }

  _prevSlide(skipAnimation = false) {
    this._with_animation("prev", skipAnimation, () => {
      const preview = this.previewTarget.firstElementChild;
      let nextSlide = this.slidesContainerTarget.lastElementChild;

      if (nextSlide.classList.contains("previewed")) nextSlide = nextSlide.previousElementSibling;

      this.slidesContainerTarget.querySelectorAll('.previewed').forEach((previewed) => {
        this.slidesContainerTarget.prepend(previewed);
        previewed.classList.remove("previewed");
      });

      if (preview) {
        preview.remove();
      }

      if (nextSlide) {
        this.slidesContainerTarget.appendChild(nextSlide);
        this.previewTarget.appendChild(nextSlide.cloneNode(true));

        nextSlide.classList.add("previewed");
      }
    });
  }


  _nextSlide(nTime = 1, skipAnimation = false) {
    this._with_animation("next", skipAnimation, () => {
      for (let i = 0; i < nTime; i++) {
        const preview = this.previewTarget.firstElementChild;
        const nextSlide = this.slidesContainerTarget.firstElementChild;

        this.slidesContainerTarget.querySelectorAll('.previewed').forEach((previewed) => {
          previewed.classList.remove("previewed");
        });

        if (preview) {
          preview.remove();
        }

        if (nextSlide) {
          this.slidesContainerTarget.appendChild(nextSlide);
          this.previewTarget.appendChild(nextSlide.cloneNode(true));

          nextSlide.classList.add("previewed");
        }
      }
    });
  }


  _with_animation(action, skipAnimation, transitionFunc) {
    if (skipAnimation || this.element.dataset.animate !== "true") return transitionFunc();

    this._clear_animation();

    const delay = parseInt(this.element.dataset.animateDelay || 500);

    this.previewTarget.classList.add("animating");
    this.slidesContainerTarget.classList.add(`animating-${action}`);

    this.animationTimeout = setTimeout(() => {
      transitionFunc();
      this._clear_animation();
    }, delay);
  }

  _clear_animation() {
    this.previewTarget.classList.remove("animating");
    this.slidesContainerTarget.classList.remove("animating-next");
    this.slidesContainerTarget.classList.remove("animating-prev");

    clearTimeout(this.animationTimeout);
  }
}
