import "@hotwired/turbo-rails";

/**
 * Based on https://github.com/hotwired/turbo/issues/792
 */
Turbo.StreamActions.push_state = function () {
  const url = this.getAttribute("url");
  const state = {
    turbo_stream_history: true,
  };
  Turbo.cache.exemptPageFromPreview();
  history.replaceState(state, "", window.location.href);
  history.pushState(state, "", url);
};

window.addEventListener("popstate", (event) => {
  if (event.state && event.state.turbo_stream_history) {
    Turbo.visit(window.location.href, {action: "replace"});
  }
});
