import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="photo"
export default class extends Controller {
  static targets = ["input", "photo"];

  connect() {
    this.inputTarget.addEventListener("change", (e) => this.updatePhotoPreview(e));
  }

  updatePhotoPreview(e) {
    this.handleFileUpload(e, (result) => {
      this.photoTarget.src = result;
    });
  }

  handleFileUpload = (event, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      callback(reader.result);
    }, false);

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}