import {get, post, put} from "../../utils/rest_client";
import {formHelpers} from "../utils/form_utils";

export default {
  props: ['pullUrl', 'pushUrl', 'regionsUrl', 'mrcsUrl', 'lsnsUrl', 'activitySectorsUrl'],
  data() {
    return {
      ready: false,
      organization: {},
      errors: {},
      activitySectors: [],
      regions: [],
      mrcs: [],
      lsns: [],
      selectedRegions: [],
      optionsRlsMrc: "optionRLS"
    }
  },
  mounted() {
    const fetchOrganization = get(this.pullUrl)
      .then(([organization]) => this.organization = organization)
      .catch((error) => console.log('Something went wrong while loading organization.', error));

    const fetchRegions = get(this.regionsUrl)
      .then(([regions]) => this.regions = regions)
      .catch((error) => console.log('Something went wrong while loading regions.', error));

    const fetchMrcs = get(this.mrcsUrl)
      .then(([mrcs]) => this.mrcs = mrcs)
      .catch((error) => console.log('Something went wrong while loading mrcs.', error));

    const fetchLsns = get(this.lsnsUrl)
      .then(([lsns]) => this.lsns = lsns)
      .catch((error) => console.log('Something went wrong while loading lsns.', error));

    const fetchActivitySectors = get(this.activitySectorsUrl)
      .then(([activitySectors]) => this.activitySectors = activitySectors)
      .catch((error) => console.log('Something went wrong while loading activity sectors.', error));

    Promise.all([fetchOrganization, fetchRegions, fetchMrcs, fetchLsns, fetchActivitySectors])
      .then(() => {
        const mrcRegions = this.organization.recordGeographicalZone.mrcIds.map((mrcId) => this.mrcs.find((mrc) => mrc.id === mrcId).regionId);
        const lsnRegions = this.organization.recordGeographicalZone.lsnIds.map((lsnId) => this.lsns.find((lsn) => lsn.id === lsnId).regionId);
        this.selectedRegions = Array.from(new Set([mrcRegions, lsnRegions].flat()));
        if (mrcRegions.length !== 0) { this.optionsRlsMrc = "optionMRC" }
        this.ready = true
      });
  },
  methods: {
    ...formHelpers('organization'),
    onMediaImageChange(e) {
      [...e.target.files].forEach((file, i) => {
        this.handleImageUpload(file, (result) => {
          this.addNested('images');
          this.organization.images[this.organization.images.length - 1].imageDataUri = result;
          this.organization.images[this.organization.images.length - 1].imageUrl = result;
        });
      });
    },
    handleSave() {
      const saveMethod = this.organization.id ? put : post;
      saveMethod(this.pushUrl, {organization: this.organization})
        .then(([, {redirectUrl}]) => {
          window.location = redirectUrl;
        }).catch((errors) => {
        this.errors = errors;
        window.scrollTo(0, 0,);
      })
    },
    onFileChange(e) {
      this.handleFileUpload(e, (result) => {
        this.organization.logoDataUri = result
        this.organization.logoUrl = result
      });
    },
    addService() {
      this.organization.services ||= [];
      this.organization.services.push({ weekSchedule: {}, serviceActivitySectors: this.organization.activitySectorIds.map(
            (as) => ({ activitySectorId: as, enabled: false, activitySectorLabelIds: [] })
        ) });
    },
    onServiceWeekScheduleUseToggleChange(index) {
      this.organization.services[index].weekSchedule._destroy = this.organization.services[index].useOrganizationSchedule
    },
    onServiceWeekScheduleToggleChange(day, index) {
      if (!this.organization.services[index].weekSchedule[day]) {
        this.organization.services[index].weekSchedule[`${day}From`] = "";
        this.organization.services[index].weekSchedule[`${day}To`] = "";
      }
    },
  },
  computed: {

  },
  watch: {
    selectedRegions(newValue, oldValue) {
      if (oldValue.length > 0 && newValue.length < oldValue.length) {
        const removedRegionIds = oldValue.filter((oldVal) => !newValue.find((newVal) => newVal === oldVal));

        this.organization.recordGeographicalZone.mrcIds = this.organization.recordGeographicalZone.mrcIds
            .filter((mrcId) => !removedRegionIds.includes(this.mrcs.find((mrc) => mrc.id === mrcId).regionId));
        this.organization.recordGeographicalZone.lsnIds = this.organization.recordGeographicalZone.lsnIds
            .filter((lsnId) => !removedRegionIds.includes(this.lsns.find((lsn) => lsn.id === lsnId).regionId));
      }
    },
    "organization.activitySectorIds"(newValue, oldValue) {
      if (oldValue === undefined) return;

      if (oldValue.length > 0 && newValue.length < oldValue.length) {
        const removedSectorIds = oldValue.filter((oldVal) => !newValue.find((newVal) => newVal === oldVal));

        this.organization.services.forEach((s) => {
          s.serviceActivitySectors.forEach((sas) => {
            if (removedSectorIds.includes(sas.activitySectorId)) {
              sas._destroy = true;
            }
          });
        });
      } else if (newValue.length > oldValue.length) {
        const addedSectorIds = newValue.filter((oldVal) => !oldValue.find((newVal) => newVal === oldVal));
        addedSectorIds.forEach((addedSectorId) => {
          this.organization.services.forEach((s) => {
            s.serviceActivitySectors.push({ activitySectorId: addedSectorId, enabled: false, activitySectorLabelIds: [] })
          });
        });
      }
    }
  },
};
