import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="record-geographical-zone"
export default class extends Controller {
  static targets = ["regions", "trash", "lsns", "mrcs", "lsnsToggle", "mrcsToggle"];

  connect() {
    this.onToggleChange();
    this.mrcsToggleTarget.addEventListener('change', (e) => this.onToggleChange());
    this.lsnsToggleTarget.addEventListener('change', (e) => this.onToggleChange());
    this.regionsTarget.addEventListener('change', (e) => this.onSelectedRegionsChanged());
    this.trashTargets.forEach((t) => t.addEventListener('click', (e) => this.onTrashClicked(e, t)));
  }

  onToggleChange() {
    this.lsnsTargets.forEach((target) => {
      if (this.lsnsToggleTarget.checked) {
        target.classList.remove("d-none");
      } else {
        target.classList.add("d-none");
        this.clearSelection(this.lsnsTargets);
      }
    });

    this.mrcsTargets.forEach((target) => {
      if (this.mrcsToggleTarget.checked) {
        target.classList.remove("d-none");
      } else {
        target.classList.add("d-none");
        this.clearSelection(this.mrcsTargets);
      }
    });
  }

  clearSelection(targets) {
    targets.forEach((target) => {
      target.querySelectorAll('input[type="checkbox"]').forEach((input) => {
        input.checked = false;
        input.classList.remove("is-valid");
      });
    });
  }

  onSelectedRegionsChanged() {
    const originalForm = this.element.closest('form');

    const form = document.createElement("form");
    form.action = originalForm.action;
    form.method = originalForm.method;
    form.dataset.turboFrame = this.element.parentElement.id;
    form.classList.add("d-none");
    this.element.parentElement.append(form); // Inside the frame so it gets removed on reload.

    form.innerHTML = this.element.innerHTML;

    if (originalForm.querySelector('input[name="_method"]')) {
      form.appendChild(originalForm.querySelector('input[name="_method"]').cloneNode(false));
    }

    const regionsInput = document.createElement("input");
    regionsInput.name = "region_ids";
    regionsInput.value = Array.from(this.regionsTarget.querySelectorAll("option:checked")).map((o) => o.value);
    form.appendChild(regionsInput);

    form.requestSubmit();
  }

  onTrashClicked(e, target) {
    e.preventDefault();

    const option = this.regionsTarget.querySelector(`option:checked[value="${target.dataset.trashValue}"]`);

    if (option) {
      option.selected = false;
      option.closest('select').dispatchEvent(new Event("change"));
    }
  }
}
