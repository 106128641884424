import { Controller } from "@hotwired/stimulus"

import Axios from "axios";

// Connects to data-controller="bookmark"
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', () => this.toggleBookmark());
  }

  toggleBookmark() {
    this.put(this.element.dataset.url).then((response) => {}).catch((response) => {
      this.element.firstElementChild.classList.toggle('fa-solid');
      this.element.firstElementChild.classList.toggle('fa-regular');
    });
    this.element.firstElementChild.classList.toggle('fa-solid');
    this.element.firstElementChild.classList.toggle('fa-regular');
  }

  put(url) {
    return Axios.patch(url, "", {
        headers: {
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        }
      }
    );
  }
}
