import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="resource-form"
export default class extends Controller {
  activitySectorsSelect = null;

  connect() {
    super.connect();
    this.activitySectorsSelect = this.element.querySelector('select[name*="activity_sector_ids"]');
    this.activitySectorsSelect.addEventListener('change', (e) => this.onActivitySectorChanged(e));
  }


  onActivitySectorChanged(e) {
    const selected = e.target.querySelectorAll('option:checked');
    const values = Array.from(selected).map(el => el.value);
    console.log(values)

    this.element.dataset.turboFrame = "resource_form";
    this.element.setAttribute('novalidate', 'novalidate');
    this.element.requestSubmit();
  }
}
