import {Controller} from "@hotwired/stimulus"
import debounce from 'lodash.debounce';

// Connects to data-controller="resource-filters"
export default class extends Controller {
  connect() {
    this.debouncedFormSubmit = debounce(() => {
      this.element.form.requestSubmit();
    }, 500)
  }

  _formSubmit() {
    this.element.form.requestSubmit();
  }
}
