import TomSelect from 'tom-select';

/**
 * Implement Tom-Select support with
 * 1. AJAX Support
 * 2. Multiple element support
 * 3. Dynamic element support
 */

export const loadTomSelect = (elem, ajax) => {
    const ajaxExtraConfig = {
        labelField: 'text',
        valueField: 'value',
        searchField: 'text',
        optgroupField: 'class',
        plugins: ['clear_button'],
        duplicates: true, // https://github.com/orchidjs/tom-select/discussions/395 (For grouped select when value is same) (No longer works...)
        preload: true,
        lockOptgroupOrder: true,
        onChange: function() {
            if (elem.dataset.submit === "true") {
                elem.form.requestSubmit()
                elem.tomselect.clearOptions()
            }
        },
        load: (query, callback) => {
            const url = `${elem.dataset.url}?search=${encodeURIComponent(query)}`;
            fetch(url, {headers: { "Accept": "application/json", "Content-Type": "application/json" }})
              .then((response) => response.json())
              .then((json) => {
                  callback(json.items, json.optgroups);
              })
              .catch(() => {
                  callback();
              });
        },
        render: {
            no_results: function() {
                const text = document.body.dataset.locale === "fr" ? "Aucun résultat" : "No results found";
                return `<div class="no-results">${text}</div>`;
            }
        }
    };

    const createConfig = { create: elem.dataset.dynamic == "true" };

    const renderOverrides = {
        item: (data, escape) => {
            const customClass = data.$option ? data.$option.classList.toString() : '';
            return `<div class="${customClass}">${escape(data.text)}</div>`;
        },
    };

    let ts;
    // Multiple or single selection
    if (elem.getAttribute('multiple')) {
        ts = new TomSelect(elem, {
            plugins: ['remove_button'],
            hidePlaceholder: true,
            render: renderOverrides, ...createConfig, ...(ajax && ajaxExtraConfig)
        });
    } else {
        ts = new TomSelect(elem, {render: renderOverrides, ...createConfig, ...(ajax && ajaxExtraConfig)});
    }
    // Behavior improvement for multiple
    ts.on('item_add', function () {
        if (elem.multiple) {
            this.input.nextElementSibling.querySelector('input').value = '';
            this.refreshOptions();
        }
    });
};
