import {Controller} from "@hotwired/stimulus"
import PlacesAutocomplete from "stimulus-places-autocomplete"

// Connects to data-controller="places"
export default class extends PlacesAutocomplete {
  static targets = ["search", "streetAndStreetNumber"];

  connect() {
    this.countryValue = ["ca"];
    document.addEventListener("google-maps-callback", this.initAutocomplete.bind(this));
    super.connect();
  }

  disconnect() {
    document.removeEventListener("google-maps-callback", this.initAutocomplete.bind(this));
    super.disconnect();
  }

  initAutocomplete() {
    if (this.hasSearchTarget) {
      this.autocomplete = new google.maps.places.Autocomplete(this.searchTarget, this.autocompleteOptions);
      this.autocomplete.addListener('place_changed', this.placeChanged);
    } else {
      super.initAutocomplete();
    }
  }

  setGeometry(geometry) {
    super.setGeometry(geometry);

    const inputEvent = new Event('input');
    if (this.hasLatitudeTarget)
      this.latitudeTarget.dispatchEvent(inputEvent)
    if (this.hasLongitudeTarget)
      this.longitudeTarget.dispatchEvent(inputEvent)
  }

  setAddressComponents(address) {
    super.setAddressComponents(address);

    if (this.hasStreetAndStreetNumberTarget) {
      this.streetAndStreetNumberTarget.value = [(address.street_number || ''), (address.route || '')].join(" ");
    }

    // VueJS: Dispatch this event to let v-model knows about the change.
    const inputEvent = new Event('input');
    if (this.hasStreetAndStreetNumberTarget)
      this.streetAndStreetNumberTarget.dispatchEvent(inputEvent)
    if (this.hasAddressTarget)
      this.addressTarget.dispatchEvent(inputEvent)
    if (this.hasStreetNumberTarget)
      this.streetNumberTarget.dispatchEvent(inputEvent)
    if (this.hasRouteTarget)
      this.routeTarget.dispatchEvent(inputEvent)
    if (this.hasCityTarget)
      this.cityTarget.dispatchEvent(inputEvent)
    if (this.hasCountyTarget)
      this.countyTarget.dispatchEvent(inputEvent)
    if (this.hasStateTarget)
      this.stateTarget.dispatchEvent(inputEvent)
    if (this.hasCountryTarget)
      this.countryTarget.dispatchEvent(inputEvent)
    if (this.hasPostalCodeTarget)
      this.postalCodeTarget.dispatchEvent(inputEvent)
  }
}