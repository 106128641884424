/**
 * This file is getting destroyed and replaced by helpers.js
 * Goal: Only keep helpers.js and get rid of most of the non necessary stuffs here.
 */
export const formHelpers = (resourceName) => ({
  isDestroyed,
  handleFileUpload,
  handleImageUpload,
  addNested(itemName, record = undefined) {
    if (record) {
      record[itemName] ||= [];
      record[itemName].push({})
    } else {
      this[resourceName][itemName] ||= [];
      this[resourceName][itemName].push({});
    }
  },
  removeNested(itemName, index, record = undefined) {
    if (record) {
      record[itemName] = removeNestedItem(record[itemName], index);
    } else {
      this[resourceName][itemName] = removeNestedItem(this[resourceName][itemName], index);
    }
  },
  showAddNested(itemName, max) {
    return Object.keys(this[resourceName][itemName].filter(x => !isDestroyed(x))).length < max;
  },
  nested(itemName) {
    return this[resourceName][itemName];
  },
  imageDragOverHandler(e) {
    e.preventDefault();
  },
  imageDropHandler(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          handleImageUpload(file, (result) => {
            this.addNested('images')
            this[resourceName].images[this[resourceName].images.length - 1].imageDataUri = result
            this[resourceName].images[this[resourceName].images.length - 1].imageUrl = result
          });
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer.files].forEach((file, i) => {
        handleImageUpload(file, (result) => {
          this.addNested('images')
          this[resourceName].images[this[resourceName].images.length - 1].imageDataUri = result
          this[resourceName].images[this[resourceName].images.length - 1].imageUrl = result
        });
      });
    }
  },
  arrayRemove(array, index) {
    const copy = [...array];
    copy.splice(index, 1);
    return copy;
  },
  acceptInvitation(itemName, index) {
    this[resourceName][itemName][index].status = "active"
  }
  // toggleRegionCheckboxes(e, regionalName, region) {
  //   const checked = e.target.checked
  //   const regionalIds = regionalName.concat("Ids")
  //
  //   this[regionalName.concat("s")].filter((l) => l.regionId === region).forEach((element) => {
  //     if (this.nested('recordGeographicalZone')[regionalIds].indexOf(element.id) === -1 && checked) {
  //       this.nested('recordGeographicalZone')[regionalIds].push(element.id)
  //     } else if (this.nested('recordGeographicalZone')[regionalIds].indexOf(element.id) !== -1 && !checked) {
  //       this.nested('recordGeographicalZone')[regionalIds].splice(this.nested('recordGeographicalZone')[regionalIds].indexOf(element.id), 1)
  //     }
  //   })
  // }
});

const handleFileUpload = (event, callback) => {
  // Moved into a Vue JS plugin. See plugins folder.
  const reader = new FileReader();
  const filename = event.target.files?.[0]?.name;
  reader.addEventListener("load", () => {
    callback(reader.result.replace(';base64', `;name=${encodeURIComponent(filename)};base64`), filename);
  }, false);

  if (event.target.files[0]) {
    reader.readAsDataURL(event.target.files[0]);
  }
}

const handleImageUpload = (file, callback) => {
  // TODO: I don't understand why this exist if handleFileUpload seems to be a better version of this.
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    callback(reader.result);
  }, false);

  if (file) {
    reader.readAsDataURL(file);
  }
}

const removeNestedItem = (items, index) => {
  const copy = [...items];
  if (items[index].id) {
    copy[index]._destroy = true;
  } else {
    copy.splice(index, 1);
  }
  return copy;
}

// const kept = (items) => {
//   if (!items) return items;
//
//   if (Array.isArray(items)) {
//     return (items).filter((item) => !isDestroyed(item));
//   } else if (typeof (items) === 'object' && !isDestroyed(items)) {
//     return items;
//   }
// }

const isDestroyed = (item) => {
  if (!item) return false;

  return item._destroy && (item._destroy.toString() === "1" || item._destroy.toString() === "true");
}