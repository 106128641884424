import {get, post, put} from "../../utils/rest_client";
import {formHelpers} from "../utils/form_utils";

export default {
  props: ['pullUrl', 'pushUrl'],
  data() {
    return {
      ready: false,
      person: {},
      errors: {}
    }
  },
  mounted() {
    get(this.pullUrl)
      .then(([person]) => {
        this.person = person;
        this.ready = true;
      })
      .catch((error) => console.log('Something went wrong.', error));
  },
  methods: {
    ...formHelpers('person'),
    handleSave() {
      const saveMethod = this.person.id ? put : post;
      saveMethod(this.pushUrl, {person: this.person})
        .then(([, {redirectUrl}]) => {
          window.location = redirectUrl;
        }).catch((errors) => {
        this.errors = errors;
        window.scrollTo(0, 0,);
      })
    },
    onFileChange(e) {
      this.handleFileUpload(e, (result) => {
        this.person.photoDataUri = result;
        this.person.photoUrl = result;
      });
    },
  },
  watch: {}
};
