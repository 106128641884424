import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="anchors"
export default class extends Controller {
  static targets = ["buttons", "container"];

  connect() {

  }

  buttonsTargetConnected(button) {
    button.addEventListener('click', (e) => {
      e.preventDefault();

      document.getElementById(button.getAttribute('href').replace('#', '')).scrollIntoView(true);
    })
  }

  /**
   * This fix is required because we use bootstrap's scroll spy on VueJS views and the container is render after
   * page has been loaded. It seems to work anyway on Chrome/Firefox but to break on Safari.
   */
  containerTargetConnected(container) {
     const instance = Bootstrap.ScrollSpy.getInstance(container);

     if (!instance) {
       new Bootstrap.ScrollSpy(container, {
         target: container.dataset.bsTarget
       })
     } else {
       instance.refresh();
     }
  }
}
