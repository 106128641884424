import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["back", "submenu", "menu"];

  connect() {
    if (this.element.nodeName === "SELECT") {
      this.injectDropdownIntoDOM();
    } else {
      document.body.addEventListener('click', () => this.closeDropdown());
      this.element.addEventListener('click', (e) => e.stopPropagation());
      this.element.querySelector('label').addEventListener('click', (e) => this.onDropdownClick(e));
      this.element.querySelectorAll('li').forEach((li) => {
        li.addEventListener('click', (e) => this.onItemClick(e, li));
      });
      this.element.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
        checkbox.addEventListener('click', (e) => this.onCheckboxClick(e, checkbox));
      });
    }
  }

  onDropdownClick(e) {
    this.openDropdown();
  }

  onItemClick(e, li) {
    if (!li.dataset.submenu) return;
    if (this.submenuTarget.contains(li)) return;

    this.isSubmenuOpen(li) ? this.closeSubmenu() : this.openSubmenu(li);
  }

  onCheckboxClick(e, checkbox) {
    const updateCheckboxesState = () => {
      const item = checkbox.closest('li');
      if (this.submenuTarget.contains(checkbox)) {
        const submenuId = item.dataset.submenu;

        const total = this.submenuTarget
          .querySelectorAll(`li[data-submenu="${submenuId}"] input[type="checkbox"]`)
          .length;

        const checked = this.submenuTarget
          .querySelectorAll(`li[data-submenu="${submenuId}"] input[type="checkbox"]:checked`)
          .length;

        const mainCheckbox = this.element.querySelector(`li[data-submenu="${submenuId}"] input[type="checkbox"]`);
        mainCheckbox.checked = checked !== 0;
        mainCheckbox.indeterminate = checked > 0 && checked !== total;
      } else {
        const submenuId = item.dataset.submenu;
        if (submenuId) {
          const checkboxes = this.submenuTarget
            .querySelectorAll(`li[data-submenu="${submenuId}"] input[type="checkbox"]`);
          const checked = this.submenuTarget
            .querySelectorAll(`li[data-submenu="${submenuId}"] input[type="checkbox"]:checked`);
          checkboxes.forEach((input) => input.checked = checked.length === 0);
        }
        if (this.isSubmenuOpen(item)) {
          e.stopPropagation();
        }
      }
    }

    updateCheckboxesState();

    checkbox.form.requestSubmit();
  }

  filterOptions() {
    this.closeSubmenu();
    this.menuTarget.querySelectorAll('li').forEach((li) => {
      li.classList.toggle("search-hidden", this.isSearchMatch(li));
    });
    this.menuTarget.querySelectorAll('li:not(.search-hidden):not(.with-submenu)').forEach((li) => {
      const parentMenu = this.menuTarget.querySelector(`.with-submenu[data-submenu="${li.dataset.submenu}"]`)
      if (parentMenu) parentMenu.classList.remove("search-hidden");
    });
  }

  isSearchMatch(li) {
    const filterRegex = new RegExp(`\\b${this.menuTarget.querySelector('input').value.toLowerCase()}`);
    const label = li.querySelector('label').textContent.toLowerCase();
    return !label.match(filterRegex)
  }

  backTargetConnected(element) {
    element.addEventListener('click', (e) => {
      e.stopPropagation();
      this.closeSubmenu();
    });
  }

  isSubmenuOpen(li) {
    if (!(this.submenuTarget.classList.contains("opened"))) { return false }

    if (li) {
      return !this.submenuTarget.querySelector(`li[data-submenu="${li.dataset.submenu}"]`).classList.contains("d-none");
    } else {
      return this.submenuTarget.classList.contains("opened");
    }
  }

  openDropdown() {
    this.element.classList.toggle("opened");
  }

  closeDropdown() {
    this.element.classList.remove("opened");
    this.element.querySelectorAll(".opened").forEach((elem) => elem.classList.remove("opened"));
  }

  openSubmenu(li) {
    const topY = li.getBoundingClientRect().y - (this.element.getBoundingClientRect().y + this.element.getBoundingClientRect().height);
    this.submenuTarget.style.top = `${topY}px`;
    this.submenuTarget.classList.add("opened");
    this.submenuTarget.querySelectorAll('li').forEach((subLi) => {
      if (subLi.dataset.submenu === li.dataset.submenu) {
        subLi.classList.remove("d-none");
      } else {
        subLi.classList.add("d-none");
      }
    });
  }

  closeSubmenu() {
    this.submenuTarget.classList.remove("opened");
    this.submenuTarget.querySelectorAll('li:not(.d-none)').forEach((li) => li.classList.add("d-none"));
  }

  injectDropdownIntoDOM() {
    const container = document.createElement("div");
    container.classList.add("mc2-dropdown");
    container.dataset.controller = "dropdown";

    const selectedItemLabel = document.createElement("label");
    selectedItemLabel.textContent = this.element.getAttribute("prompt");
    container.appendChild(selectedItemLabel)

    const contentDiv = document.createElement("div");
    contentDiv.classList.add("content");
    contentDiv.dataset.dropdownTarget = "menu";

    const searchInput = document.createElement("input");
    searchInput.placeholder = "Rechercher";
    searchInput.type = "text";
    searchInput.dataset.action = "input->dropdown#filterOptions"
    contentDiv.appendChild(searchInput);

    const list = document.createElement("ul");
    contentDiv.appendChild(list);

    const submenu = document.createElement("div");
    submenu.classList.add("submenu");
    submenu.dataset.dropdownTarget = "submenu";
    contentDiv.appendChild(submenu);

    const backArrow = document.createElement("a");
    backArrow.dataset.dropdownTarget = "back";
    submenu.appendChild((backArrow));

    const backArrowIcon = document.createElement("i");
    backArrowIcon.classList.add("fa-solid", "fa-arrow-left");
    backArrow.appendChild(backArrowIcon);

    const submenuList = document.createElement("ul");
    submenu.appendChild(submenuList);

    // For grouped select
    this.element.querySelectorAll(":scope > optgroup").forEach((optGroup, index) => {
      const item = document.createElement("li");
      item.classList.add("with-submenu");
      item.dataset.submenu = index.toString();

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.dataset.type = "optgroup";
      if (Array.from(optGroup.querySelectorAll("option")).some((option) => option.hasAttribute("selected"))) {
        checkbox.checked = true;
        checkbox.indeterminate = !(Array.from(optGroup.querySelectorAll("option")).every((option) => option.hasAttribute("selected")));
      }
      item.appendChild(checkbox);

      const label = document.createElement("label");
      label.textContent = optGroup.label;
      item.appendChild(label);

      list.appendChild(item);

      optGroup.querySelectorAll("option").forEach((option) => {
        const subLi = document.createElement("li");
        subLi.dataset.submenu = index;
        subLi.classList.add("d-none");

        const subCheckbox = document.createElement("input");
        subCheckbox.type = "checkbox";
        subCheckbox.name = this.element.name;
        subCheckbox.value = option.value;
        subCheckbox.checked = option.hasAttribute("selected");
        subLi.appendChild(subCheckbox);

        const subLabel = document.createElement("label");
        subLabel.textContent = option.textContent;
        subLabel.for = ""; // TODO
        subLi.appendChild(subLabel);

        submenuList.appendChild(subLi);
      });
    });

    // For standard select
    this.element.querySelectorAll(":scope > option").forEach((option, index) => {
      const item = document.createElement("li");

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.name = this.element.name;
      checkbox.value = option.value;
      checkbox.checked = option.hasAttribute("selected");
      item.appendChild(checkbox);

      const label = document.createElement("label");
      label.textContent = option.textContent;
      label.for = ""; // TODO
      item.appendChild(label);

      list.appendChild(item);
    });

    container.appendChild(contentDiv);
    this.element.replaceWith(container);
  }
}
