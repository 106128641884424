import {Controller} from "@hotwired/stimulus";
import axios from "axios";
import { parse, stringify } from 'qs'

export default class extends Controller {
  static targets = ["map", "markers", "polygons", "searchbox"];

  connect() {
    if (typeof (google) !== 'undefined') {
      this.initMap();
    } else {
      document.addEventListener("google-maps-callback", () => {
        this.initMap();
      });
    }
  }

  initMap() {
    this.initializeMap()
    this.markers.forEach(marker => {
      this.addMarker(marker);
    });
    [...new Map(this.polygonsTargets.map((polygon) => [
        polygon.dataset.polygonId, polygon
    ])).values()].forEach(polygon => {
      this.createPolygon(polygon);
    })
    this.updateBounds();
  }

  initializeMap() {
    this.map = new google.maps.Map(this.mapTarget, {disableDefaultUI: true});
    this.bounds = new google.maps.LatLngBounds();
    this.clickable = this.mapTarget.dataset.disableClick !== "true";
    this.searchBoxInit();
    this.infoWindow = null;
    this.markers = this.markersTargets.map((li) => this.markerInit(li))
  }

  searchBoxInit() {
    if (this.clickable) {
      const searchBox = this.searchboxTarget.cloneNode(true)
      searchBox.classList.remove('d-none');
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(searchBox);
      const options = {componentRestrictions: {country: "ca"}};
      this.searchBox = new google.maps.places.Autocomplete(searchBox.querySelector("input"), options);
      this.searchBox.addListener("place_changed", () => {
        this.map.setCenter(this.searchBox.getPlace().geometry.location);
        this.map.setZoom(11);
      });
    }
  }

  markerInit(address) {
    return new google.maps.Marker({
      content: address.getAttribute("data-content"),
      icon: this.clickable ? this.mapTarget.dataset.whitePin : this.mapTarget.dataset.pin,
      position: {
        lat: parseFloat(address.getAttribute("data-latitude")),
        lng: parseFloat(address.getAttribute("data-longitude"))
      },
      map: this.map
    });
  }

  addMarker(marker) {
    if (this.clickable) {
      marker.addListener("click", () => {
        if (this.infoWindow) {
          this.infoWindow.close()
        }
        this.markers.forEach(marker => marker.setIcon(this.mapTarget.dataset.whitePin));
        marker.setIcon(this.mapTarget.dataset.pin)
        this.infoWindow = new google.maps.InfoWindow({
          content: marker.content
        });
        let map = this.map;
        this.infoWindow.open({
          anchor: marker,
          map,
        });
      });
    }
    this.bounds.extend(marker.position)
  }

  createPolygon(polygon) {
    // Create a polygon object
    const encoded_str = polygon.getAttribute('data-encoded-polygon')
    const polygonRegion = new google.maps.Polygon({
      path: google.maps.geometry.encoding.decodePath(encoded_str),
      strokeColor: "#EC8811",
      fillColor: "#EC8811",
      fillOpacity: 0.4,
      strokeOpacity: 1.0,
      strokeWeight: 0.75,
      map: this.map
    });
    polygonRegion.addListener("click", () => {
      const lsnId = polygon.getAttribute('data-polygon-id');
      const params = parse(window.location.search, { ignoreQueryPrefix: true });
      axios.get(window.location.href.split('?')[0], {
        headers: {Accept: "text/vnd.turbo-stream.html" },
        params: {search: params.search, filters: { ...(params.filters || {}), lsn_ids: [lsnId] }, refresh_filters: true},
        paramsSerializer: { serialize: (params) => stringify(params, {arrayFormat: 'brackets'}) },
      }).then((response) => Turbo.renderStreamMessage(response.data));
    })
    // Registering every polygon point for correct map bounding
    polygonRegion.getPath().getArray().forEach(point => {
      this.bounds.extend(point)
    });
  }

  updateBounds() {
    this.isGeolocalizable().then((isGeolocalizable) => {
      if (isGeolocalizable && this.clickable) {
        navigator.geolocation.getCurrentPosition((i) => this.centerOnLocation(i))
      } else if (this.bounds.isEmpty()) {
        this.map.setCenter(new google.maps.LatLng(47.03, -73.33)); // QC address
        this.map.setZoom(5);
      } else if (this.markers.length === 1) {
        this.map.setCenter(this.markers[0].getPosition());
        this.map.setZoom(15);
      } else {
        this.map.fitBounds(this.bounds);
      }
    });
  }

  isGeolocalizable() {
    return new Promise((resolve, reject) => {
      navigator.permissions.query({name: "geolocation"}).then((result) => {
        if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition((i) => this.centerOnLocation(i));
          resolve(false)
        } else {
          resolve(result.state === "granted");
        }
      }).catch(reject);
    })
  }

  centerOnLocation(currentLocation) {
    this.map.setCenter(new google.maps.LatLng(currentLocation.coords.latitude, currentLocation.coords.longitude));
    this.map.setZoom(10);
  }
}