import axios, { AxiosInstance, AxiosResponse } from "axios";

const client = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": document.body.dataset.locale
  }
});


function formatOptions(options) {
  // To be done when we need it.
  return { ...options };
}

function formatResponse({ data, headers, status }) {
  return [data, { headers, status, redirectUrl: headers["location"] }];
}

export function get(url, queryParams = {}) {
  return new Promise((resolve, reject) => {
    client
      .get(url, { params: queryParams })
      .then((response) => resolve(formatResponse(response)))
      .catch((err) => reject(err.response.data));
  });
}

export function post(url, params = {}, options = {}) {
  return new Promise((resolve, reject) => {
    client
      .post(url, params, { ...formatOptions(options) })
      .then((response) => resolve(formatResponse(response)))
      .catch((err) => reject(err.response.data));
  });
}

export function patch(url, params = {}, options = {}) {
  return new Promise((resolve, reject) => {
    client
      .patch(url, params, { ...formatOptions(options) })
      .then((response) => resolve(formatResponse(response)))
      .catch((err) => reject(err.response.data));
  });
}

export function put(url, params = {}, options = {}) {
  return new Promise((resolve, reject) => {
    client
      .put(url, params, { ...formatOptions(options) })
      .then((response) => resolve(formatResponse(response)))
      .catch((err) => reject(err.response.data));
  });
}

export function destroy(url, queryParams = {}) {
  return new Promise((resolve, reject) => {
    client
      .delete(url, { params: queryParams })
      .then((response) => resolve(formatResponse(response)))
      .catch((err) => reject(err.response.data));
  });
}
