import { Controller } from "@hotwired/stimulus"
import { createApp } from 'vue'

import OrganizationForm from "../web/forms/organization_form";
import PartnershipForm from "../web/forms/partnership_form";
import PersonForm from "../web/forms/person_form";

// Custom Components
import Multiselect from '@vueform/multiselect'
import Collapse from "../web/components/Collapse.vue"

/* Connects to data-controller="vue"
 * Example of usage:
 * <div
 *     data-controller="vue"
 *     data-vue-component-value="PersonForm"
 *     data-vue-props-value="<%= { name: "Ruby" }.to_json %>"
 * >Inject here</div>
 */
export default class extends Controller {
  static values = {
    component: String,
    props: Object
  }

  connect() {
    this.app = createApp(this.getComponent(), this.propsValue || {});
    this.app.component('Multiselect', Multiselect);
    this.app.component('Collapse', Collapse)
    this.app.mount(this.element)
  }

  disconnect() {
    this.app.unmount();
  }

  getComponent() {
    return {
      "OrganizationForm": OrganizationForm,
      "PartnershipForm": PartnershipForm,
      "PersonForm": PersonForm
    }[this.componentValue]
  }
}
