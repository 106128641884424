import {get, post, put} from "../../utils/rest_client";
import {formHelpers} from "../utils/form_utils";

export default {
  props: ['pullUrl', 'pushUrl', 'regionsUrl', 'mrcsUrl', 'lsnsUrl', 'activitySectorsUrl'],
  data() {
    return {
      ready: false,
      partnership: {},
      errors: {},
      activitySectors: [],
      regions: [],
      mrcs: [],
      lsns: [],
      selectedRegions: [],
      optionsRlsMrc: "optionRLS",
    }
  },
  mounted() {
    const fetchPartnership = get(this.pullUrl)
        .then(([partnership]) => this.partnership = partnership)
        .catch((error) => console.log('Something went wrong while loading partnership.', error));

    const fetchRegions = get(this.regionsUrl)
        .then(([regions]) => this.regions = regions)
        .catch((error) => console.log('Something went wrong while loading regions.', error));

    const fetchMrcs = get(this.mrcsUrl)
        .then(([mrcs]) => this.mrcs = mrcs)
        .catch((error) => console.log('Something went wrong while loading mrcs.', error));

    const fetchLsns = get(this.lsnsUrl)
        .then(([lsns]) => this.lsns = lsns)
        .catch((error) => console.log('Something went wrong while loading lsns.', error));

    const fetchActivitySectors = get(this.activitySectorsUrl)
        .then(([activitySectors]) => this.activitySectors = activitySectors)
        .catch((error) => console.log('Something went wrong while loading activity sectors.', error));

    Promise.all([fetchPartnership, fetchRegions, fetchMrcs, fetchLsns, fetchActivitySectors])
        .then(() => {
          const mrcRegions = this.partnership.recordGeographicalZone.mrcIds.map((mrcId) => this.mrcs.find((mrc) => mrc.id === mrcId).regionId);
          const lsnRegions = this.partnership.recordGeographicalZone.lsnIds.map((lsnId) => this.lsns.find((lsn) => lsn.id === lsnId).regionId);
          this.selectedRegions = Array.from(new Set([mrcRegions, lsnRegions].flat()));
          if (mrcRegions.length !== 0) { this.optionsRlsMrc = "optionMRC" }
          this.ready = true
        });
  },
  methods: {
    ...formHelpers('partnership'),
    onMediaImageChange(e) {
      [...e.target.files].forEach((file, i) => {
        this.handleImageUpload(file, (result) => {
          this.addNested('images');
          this.partnership.images[this.partnership.images.length - 1].imageDataUri = result;
          this.partnership.images[this.partnership.images.length - 1].imageUrl = result;
        });
      });
    },
    onNestedFileChange(e, index, itemName) {
      const i = index;
      this.handleFileUpload(e, (result, filename) => {
        this.partnership[itemName][i].fileDataUri = result;
        this.partnership[itemName][i].fileName = filename;
      });
    },
    handleSave() {
      const saveMethod = this.partnership.id ? put : post;
      saveMethod(this.pushUrl, {partnership: this.partnership})
        .then(([, {redirectUrl}]) => {
          window.location = redirectUrl;
        }).catch((errors) => {
        this.errors = errors;
        window.scrollTo(0, 0,);
      })
    },
    onFileChange(e) {
      this.handleFileUpload(e, (result) => {
        this.partnership.logoDataUri = result
        this.partnership.logoUrl = result
      });
    },
    addProject() {
      this.partnership.projects ||= [];
      this.partnership.projects.push({ projectActivitySectors: this.partnership.activitySectorIds.map(
            (as) => ({ activitySectorId: as, enabled: false, activitySectorLabelIds: [] })
        ) });
    },
  },
  watch: {
    selectedRegions(newValue, oldValue) {
      if (oldValue.length > 0 && newValue.length < oldValue.length) {
        const removedRegionIds = oldValue.filter((oldVal) => !newValue.find((newVal) => newVal === oldVal));

        this.partnership.recordGeographicalZone.mrcIds = this.partnership.recordGeographicalZone.mrcIds
            .filter((mrcId) => !removedRegionIds.includes(this.mrcs.find((mrc) => mrc.id === mrcId).regionId));
        this.partnership.recordGeographicalZone.lsnIds = this.partnership.recordGeographicalZone.lsnIds
            .filter((lsnId) => !removedRegionIds.includes(this.lsns.find((lsn) => lsn.id === lsnId).regionId));
      }
    },
    "partnership.activitySectorIds"(newValue, oldValue) {
      if (oldValue === undefined) return;

      if (oldValue.length > 0 && newValue.length < oldValue.length) {
        const removedSectorIds = oldValue.filter((oldVal) => !newValue.find((newVal) => newVal === oldVal));

        this.partnership.projects.forEach((s) => {
          s.projectActivitySectors.forEach((sas) => {
            if (removedSectorIds.includes(sas.activitySectorId)) {
              sas._destroy = true;
            }
          });
        });
      } else if (newValue.length > oldValue.length) {
        const addedSectorIds = newValue.filter((oldVal) => !oldValue.find((newVal) => newVal === oldVal));
        addedSectorIds.forEach((addedSectorId) => {
          this.partnership.projects.forEach((s) => {
            s.projectActivitySectors.push({ activitySectorId: addedSectorId, enabled: false, activitySectorLabelIds: [] })
          });
        });
      }
    }
  }
};
