import { Controller } from "@hotwired/stimulus"

window.onloadTurnstileCallback = onloadTurnstileCallback;
function onloadTurnstileCallback() {
  let instance;
  while ((instance = onloadTurnstileCallback.waiting.shift())) {
    instance.connect();
  }
}
onloadTurnstileCallback.waiting = []

// Connects to data-controller="cloudflare-turnstile"
export default class extends Controller {
  static values = {
    siteKey: String
  };

  connect() {
    if (this.widgetId) return;

    if (window.turnstile) {
      this.element.innerHTML = '';
      this.widgetId = window.turnstile.render(this.element, {
        sitekey: this.siteKeyValue,
        language: document.documentElement.lang
      });
    }
    else {
      onloadTurnstileCallback.waiting.push(this);
    }
  }

  disconnect() {
    let waitIndex = onloadTurnstileCallback.waiting.indexOf(this);
    if (waitIndex !== -1) {
      onloadTurnstileCallback.waiting.splice(waitIndex, 1);
    }

    if (this.widgetId) {
      turnstile.remove(this.widgetId);
      this.widgetId = null;
    }
  }

  reset() {
    return turnstile.reset(this.widgetId);
  }
}
